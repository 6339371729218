



















































































import { Api, CancellationInfo, PagedResults, reasonToLabel, SubscriptionCancellationReason } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Notes from './Notes.vue'

@Component({
  components: {
    Notes
  }
})
export default class CancellationTable extends Mixins(ComponentHelper) {
  @Prop({ required: true }) data!: PagedResults<CancellationInfo<{notes: true}>>

  addingNote: boolean = false

  reasonToLabel = reasonToLabel
  cancellationReasons = SubscriptionCancellationReason
  isChangingReason = false

  newReasonMap: Record<number, SubscriptionCancellationReason> = {}

  async handleReasonChangeClick (row: CancellationInfo<{ notes: true }>) {
    try {
      if (this.isChangingReason) { return }
      this.isChangingReason = true
      const { cancellation_id: cancellationId } = row
      const reason = this.newReasonMap[cancellationId]

      await Api.editCancellationReason(cancellationId, reason)
      row.reason = reason
    } catch (error) {
      console.error(error)
    } finally {
      this.isChangingReason = false
    }
  }
}

